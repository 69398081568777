import React, {Component} from "react"
import {graphql, StaticQuery} from "gatsby";
import Layout from "components/Layout/LayoutNewYork"
import LandingBlock from "components/new-york/HomePage/LandingBlock"
import Winner from "components/new-york/HomePage/Winner"
import FluxBlock from "components/new-york/HomePage/FluxBlock"
import Cookies from 'universal-cookie'
import AudioBlock from "components/new-york/HomePage/AudioBlock";
import ReviewSlider from "components/new-york/HomePage/ReviewSlider"
import GreatScottBlock from "components/new-york/HomePage/GreatScottBlock"
import { Helmet } from "react-helmet";
import NewSaleTicketBlock from "../../components/new-york/NewSaleTicketBlock";
import QRcodeBlock from "../../components/shared/QRcodeBlock";

const cookies = new Cookies();
const COOKIE_DOMAIN = "backtothefuturemusical.com";
let expiry = new Date();
expiry = new Date(expiry.setMonth(expiry.getMonth() + 3))

class IndexPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            audience: 'other',
            musicStopped: false,
            videoStopped: false,
            modalOpen: true,
        }

        this.stopMusic = this.stopMusic.bind(this);
        this.stopVideo = this.stopVideo.bind(this);


    }


    componentDidMount() {
        if (!cookies.get('awardsModal')) {
            this.setState({
                modalOpen: false,
            });

        }
    }

    stopMusic = (musicStopped) => {
        this.setState({
            musicStopped
        })
    }

    stopVideo = (videoStopped) => {
        this.setState({
            videoStopped
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.musicStopped !== this.state.musicStopped) {
            this.setState({
                musicStopped: this.state.musicStopped
            })
        }
    }

    toggleModal = (e) => {
        e.preventDefault();
        cookies.set('awardsModal', true, {
            expires: expiry,
            domain: COOKIE_DOMAIN
        });
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    };

    render() {
        return (
            <>
             <Helmet>
             <link rel="alternate" href={`https://www.backtothefuturemusical.com/new-york/`} hreflang="en-us" />
              <link rel="alternate" href={`https://www.backtothefuturemusical.com/london/`} hreflang="en-gb" />
          
            <script
              id="satisfiScript"
              src="https://chat.satis.fi/popup/embedder?popupId=17903"
            ></script>
          
            </Helmet>
            <Layout title="Back to the Future The Musical | Broadway, New York"
                    booking="/new-york/tickets/"
                    description="The official website for Back to the Future The Musical. Now playing on Broadway at the Winter Garden Theatre in New York."
                    >
                <LandingBlock/>
                <ReviewSlider />
                <Winner/>
                <NewSaleTicketBlock homepage />
                {/* <ReviewSlider/> */}
                <FluxBlock
                    booking="/new-york/tickets/"/>
                <AudioBlock
                    stopMusic={this.stopMusic}
                    stopVideo={this.stopVideo}
                    videoStopped={this.state.videoStopped}
                    musicStopped={this.state.musicStopped}
                    title="HEAR SOME HEAVY TUNES FROM THE SHOW"
                    />
                <span className="anchor" id="video" />
                <GreatScottBlock
                    stopMusic={this.stopMusic}
                    stopVideo={this.stopVideo}
                    videoId="Ov7RRGrNv_s"
                    videoStopped={this.state.videoStopped}
                    musicStopped={this.state.musicStopped}
                    booking="/new-york/tickets/"/>
                    <QRcodeBlock />
            </Layout>
                    </>
        )
    }
}

export default IndexPage
